import { Injectable } from '@angular/core';
import { BrowserKeys, LocalStorageKeys } from "../constants/common.constant";
import { CommonService } from "./common.service";
import { Utilities } from "../utilities";
import { User } from '../model/user';
// import { EventService } from './event.service';
import { BehaviorSubject, catchError, filter, map } from 'rxjs';
import { Environment, Sync } from '../constants/api.constant';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    tokenKey = LocalStorageKeys.TOKEN;
    userKey = LocalStorageKeys.USER;
    userData: any;
    userDataToken: any;
    isApiCalling = false;
    twoFAToken: any
    private handlerTwoFAToken = new BehaviorSubject('');
    loginUserData: any;

    constructor(private commonService: CommonService, private http: HttpClient, private readonly env: Environment,
        // private eventService: EventService
    ) { }

    saveUserData(userData: any) {
        this.userData = userData;
        this.commonService.setLocalStorageObject(this.userKey, userData);
        // this.eventService.broadcast(EventEmitterType.tokenUpdated, null)

    }
    saveUserToken(data: any) {
        return Promise.resolve().then(() => {
            this.userDataToken = data;
            this.commonService.setLocalStorageString(this.tokenKey, data);
        })
    }
    getUserKeyData(): any {
        return this.commonService.getLocalStorageObject(this.userKey);
    }

    getUserData(): User {
        return this.userData ? this.userData.user : this.commonService.getLocalStorageObject(this.userKey) ? this.commonService.getLocalStorageObject(this.userKey).user : {};
    }

    isUserLoggedIn(): boolean {
        const token = this.commonService.getLocalStorageString(this.tokenKey);
        return !Utilities.isEmpty(token);
    }

    clearUserData(): void {
        this.commonService.clearLocalStorage();
    }

    getDecodedJWTToken(): any {
        const token = this.commonService.getLocalStorageString(LocalStorageKeys.TOKEN);
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    }
    getDeconded2FAToken() {
        if (this.handlerTwoFAToken.getValue()) {
            const base64Url = this.handlerTwoFAToken.getValue().split('.')[1];
            const base64 = base64Url.replace('-', '+').replace('_', '/');
            return JSON.parse(window.atob(base64));
        } else {
            return false;
        }
    }
    setCustomerId(value: any) {
        this.commonService.setLocalStorageObject(LocalStorageKeys.CUSTOMERID, value);
    }
    getCustomerId() {
        return this.commonService.getLocalStorageObject(LocalStorageKeys.CUSTOMERID);
    }
    getUserCustomerId() {
        return this.commonService.getLocalStorageObject(LocalStorageKeys.USER).customerId;
    }
    updateTwoFAHandler(data: any, loginData: any): void {
        this.twoFAToken = data;
        this.loginUserData = loginData;
        this.handlerTwoFAToken.next(data);
    }
    getTwoFA() {
        return this.handlerTwoFAToken.getValue();
    }
    getDecodedFromRes(res: any) {
        if (res) {
            const base64Url = res.split('.')[1];
            const base64 = base64Url.replace('-', '+').replace('_', '/');
            return JSON.parse(window.atob(base64));
        } else {
            return false;
        }
    }

    checkSyncStauts(isProject: boolean, id: number, floorId: number, syncId: number, folderName: string) {
        const data = 'Bearer ' + this.commonService.getLocalStorageString(BrowserKeys.token);
        const httpOptions: any = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': data,
                'customerId': this.commonService.getLocalStorageObject(LocalStorageKeys.CUSTOMERID).customerId.toString()
            }),
            reportProgress: true,
        };
        let url: any = '';
        if (isProject) {
            url = this.env.apiPath + 'secure/api/project/' + id + '/floor/' + floorId + '/checksyncstatus/' + folderName + '/' + syncId
        } else {
            url = this.env.apiPath + 'secure/api/site/' + id + '/floor/' + floorId + '/checksyncstatus/' + folderName + '/' + syncId
        }
        const req = new HttpRequest('GET', url, httpOptions);

        return this.http.request(req);
    }
  
} 