import { Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import { Environment } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class MixpanelService {
  
  constructor(private readonly env: Environment) {}

  /**
     * Initialize mixpanel.
     *
     * @param {string} userToken
     * @memberof MixpanelService
     */
  init(userToken: string, userKeyData: any): void {
    mixpanel.init(this.env.mixPanelToken);
    let name = userKeyData.user.name !== null ? userKeyData.user.name : userKeyData.user.email;
    let email = userKeyData.user.email;
    let userId = userKeyData.userId;
    let currentDate = moment.utc(new Date()).format('YYYY-MM-DD h:mm A').toString();
    mixpanel.people.set({ "$name": name, "$email": email, '$last_seen': currentDate, '$id': userId, '$employer': (userKeyData.user.providerId !== null) ? userKeyData.user.providerName : userKeyData.user.providerEmployer, });
    mixpanel.identify(userToken);
}

/**
 * Push new action to mixpanel.
 *
 * @param {string} id Name of the action to track.
 * @param {*} [action={}] Actions object with custom properties.
 * @memberof MixpanelService
 */
  track(id: string, action: any = {}): void {
      mixpanel.track(id, action);
  }
  resetMixPanel() {
      mixpanel.reset();
  }
}
