import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { MixpanelService } from './mixpanel.service';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class MixpanelObjectService {

  constructor(private mixpanelService: MixpanelService,
    private authService: AuthService) { }
  trackEvent(eventType: any, object: any, eventName?: any) {
    const userKeyData = this.authService.getUserKeyData();
    const trackMixPanel: any = {
      'User Id': userKeyData.user.id,
      'User Email': (userKeyData.user.email !== null) ? userKeyData.user.email : '',
      'User Name': (userKeyData.user.name !== null) ? userKeyData.user.name : '',
      'Employer': (userKeyData.user.providerId !== null) ? userKeyData.user.providerName : userKeyData.user.providerEmployer,
      'Application': 'Web',
      'Timestamp': moment.utc(new Date()).format('YYYY-MM-DD h:mm A').toString(),
    }
    for (const property in object) {
      trackMixPanel[property] = object[property];
    }
    const name = userKeyData.user.name !== null ? userKeyData.user.name : userKeyData.user.email
    this.mixpanelService.init(userKeyData.user.id + ' (' + name + ')', userKeyData);
    this.mixpanelService.track(eventType, trackMixPanel);
  }
}
