import { Equipment } from "./equipment";

export class Category {
    id!: number | null;
    name!: string;
    equipments!: Equipment[];
    isOpen?: boolean;
    displayName?: string;
    pinColor?: string;
    key?:any;
}


export class FilterCategory {
    isChecked!: any;
    category!: Category;
    type?: any;
}


export class PartCount {
    name!: string;
    count!: number;
}

export class CategoryCount {
    name!: string;
    count!: number;
    parts: any[] = [];
}
