import { Injectable } from '@angular/core';
import { filter, map, Subject, Subscription } from 'rxjs';

interface Message {
  type: string;
  payload: any;
}

type MessageCallback = (payload: any) => void;

@Injectable({
  providedIn: 'root'
})
export class EventService {

  private handler = new Subject<Message>();

  broadcast(type: string, payload: any) {
      this.handler.next({ type, payload });
  }

  subscribe(type: string, callback: MessageCallback): Subscription {
      return this.handler.pipe(
          filter((message: any) => message.type === type),
          map((message: any) => message.payload))
          .subscribe(callback);
  }
}
