import { Injectable } from "@angular/core";
import { Utilities } from "../utilities";
import { Location } from '@angular/common'
import { DomSanitizer } from '@angular/platform-browser';
import { LocalStorageKeys, StorageKey } from '../constants/common.constant';


@Injectable({
    providedIn: 'root'
})
export class CommonService {
    isUpdateBtnClck = false;
    constructor(private _location: Location, private sanitizer: DomSanitizer) { }

    setLocalStorageString(key: string, value: string) {
        return Promise.resolve().then(function () {
            window.localStorage.setItem(key, value);
        });
    }

    getLocalStorageString(key: string): any {
        return window.localStorage.getItem(key);
    }
    setLocalStorageObject(key: string, value: object): void {
        try {
            window.localStorage.setItem(key, JSON.stringify(value));
        } catch (e) {
            console.log(e)
        }
    }

    getLocalStorageObject(key: string): any {
        const temp: any = window.localStorage.getItem(key);
        if (Utilities.isNull(temp)) {
            return null;
        }
        return JSON.parse(temp);
    }
    removeItem(key: string) {
        window.localStorage.removeItem(key);

    }
    clearLocalStorage() {
        // window.localStorage.clear();
        localStorage.removeItem(LocalStorageKeys.TOKEN);
        localStorage.removeItem(StorageKey.LastAssignee);
    }
    addEquipmentPanelHeightFromWindows(page: any): number {
        let height = 0;
        if (page === 'equipment-list') {
            height = 150;
        } else if (page === 'floor-detail') {
            height = 150;
        } else if (page === 'create-edit') {
            height = 100;
        }
        return window.innerHeight - height;
    }

    checkBrowser(): boolean {
        const trident = !!navigator.userAgent.match(/Trident\/7.0/);
        const net = !!navigator.userAgent.match(/.NET4.0E/);
        const IE11 = trident && net;

        if (IE11) {
            return true;
        } else {
            return false;
        }
    }
    checkBrowserIsEdge(): boolean {
        const edge = !!navigator.userAgent.match(/Edge\//);

        if (edge) {
            return true;
        } else {
            return false;
        }
    }
    checkBrowserIsFireFox(): boolean {
        const firefox = !!navigator.userAgent.match(/Firefox\//);

        if (firefox) {
            return true;
        } else {
            return false;
        }
    }
    checkIsMobileDevice(): boolean {
        if (navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i)
        ) {
            return true;
        } else {
            return false;
        }
    }

    getContentPanelHeight(extraHeight?: number): number {
        const trident = !!navigator.userAgent.match(/Trident\/7.0/);
        const net = !!navigator.userAgent.match(/.NET4.0E/);
        const IE11 = trident && net;
        const IEold = (navigator.userAgent.match(/MSIE/i) ? true : false);
        const isIE = IE11 || IEold;
        let height = 0;
        if (isIE) {
            height = document.documentElement.clientHeight;
            if (extraHeight) {
                height += extraHeight;
            }
        } else {
            const body = document.body,
                html = document.documentElement;

            height = Math.max(body.scrollHeight, body.offsetHeight,
                html.clientHeight, html.scrollHeight, html.offsetHeight);

        }
        return height;
    }

    goBack() {
        return this._location.back();
    }

    getValue(title: any) {
        return this.sanitizer.bypassSecurityTrustHtml(title);
    }

    checkScaleForFilter(val: any, isConverToScale: any) {
        if (isConverToScale) {
            if (val === 18) {
                return 0.3;
            } else if (val === 24) {
                return 0.4;
            } else if (val === 30) {
                return 0.5;
            } else if (val === 36) {
                return 0.6;
            } else if (val === 42) {
                return 0.7;
            } else if (val === 48) {
                return 0.8;
            } else if (val === 54) {
                return 0.9;
            } else if (val === 60) {
                return 1;
            } else {
                return 0.9;
            }
        } else {
            if (val === '0.3' || val === '0.30') {
                return 18;
            } else if (val === '0.4' || val === '0.40') {
                return 24;
            } else if (val === '0.5' || val === '0.50') {
                return 30;
            } else if (val === '0.6' || val === '0.60') {
                return 36;
            } else if (val === '0.7' || val === '0.70') {
                return 42;
            } else if (val === '0.8' || val === '0.80') {
                return 48;
            } else if (val === '0.9' || val === '0.90') {
                return 54;
            } else if (val === '1' || val === '1.00') {
                return 60;
            } else {
                return 54;
            }
        }
    }

    // getCountFromIndexDB(key: any) {
    //     this.dbService.count(key).subscribe(
    //         (details: any) => {
    //             if (details && details > 0) {
    //                 // this.eventService.broadcast(EventEmitterType.showIndexDBPopup, {})
    //             }
    //         }, e => {
    //             console.log(e)
    //         }
    //     )
    // }

    // getAllFromIndexDB(key: any) {
    //     this.dbService.getAll(key).subscribe(
    //         (data: any) => {
    //             return data;
    //         },
    //         (error: any) => {
    //             console.log(error);
    //         }
    //     );
    // }

    getDefaultTicketAssignee(): any {
        const userData = this.getLocalStorageObject('userData');
        if (!Utilities.isEmpty(userData)) {
            return userData.defaultAssignedToId
        } else {
            return 0;
        }
    }


    getRecentFilters(type: string) {
        if (type === 'ticket') {
            return (this.getLocalStorageObject('ticket-list-filters'));
        }
        else if (type === 'site') {
            return (this.getLocalStorageObject('site-list-filters'));
        }
        else if (type === 'project') {
            return (this.getLocalStorageObject('project-list-filters'));
        }
        else if (type === 'progress') {
            return (this.getLocalStorageObject('progress'));
        }
    }
    setRecentFilters(filters: any, type: string) {
        if (type === 'ticket') {
            this.setLocalStorageObject('ticket-list-filters', filters)
        }
        else if (type === 'site') {
            this.setLocalStorageObject('site-list-filters', filters)
        }
        else if (type === 'project') {
            this.setLocalStorageObject('project-list-filters', filters)
        } else if (type === 'selectedText') {
            this.setLocalStorageObject('project-selected-text', filters)
        } else if (type === 'progress') {
            this.setLocalStorageObject('progress', filters)
        }
    }
    resetFilters(type: string) {
        if (type === 'ticket') {
            this.removeItem('ticket-list-filters');
        }
        else if (type === 'site') {
            this.removeItem('site-list-filters');
        }
        else if (type === 'project') {
            this.removeItem('project-list-filters');
        } else if (type === 'progress') {
            this.removeItem('progress')
        }
    }














}